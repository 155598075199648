<template>
  <div class="build views"
       :style="viewStyle">
    <el-button type="primary" class="button" @click="click" v-if="!isFullscreen">全屏</el-button>
    <container :id="id"
               :wscale="1"
               :target="target"
               :option="option"
               ref="container"></container>
  </div>
</template>
<script>
import init from '@/mixins/'
import screenfull from "screenfull";
export default {
  props: {
    target: String,
    option: Object,
    id: [String, Number]
  },
  mixins: [init],
  data () {
    return {
      viewStyle: {},
      isFullscreen: false
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.destroy();
  },
  methods:{
    click() {
      if (!screenfull.isEnabled) {
        this.$message({ message: '你的浏览器不支持全屏', type: 'warning' })
        return false
      }
      screenfull.toggle()
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.change)
      }
    },
    destroy() {
      if (screenfull.isEnabled) {
        screenfull.off('change', this.change)
      }
    }
  }
}
</script>
